import React, { useEffect } from "react";
import "./style.css";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login, loginSuccess } from "../../../redux/actions/users";
import { useTranslation } from "react-i18next";
import { TextDangerStyled } from "./style";
import loading from "../../../redux/actions/loading";
import { loginSso } from "../../../api/user";
import { useMsal } from "@azure/msal-react";
import AzureIcon from "../../../assets/images/icon-Microsoft.svg";

const LoginForm = () => {
  const history = useHistory();
  const { instance } = useMsal();
  const { register, errors, handleSubmit } = useForm();

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(["login"]);

  const onSubmit = (data) => {
    dispatch(login(data, history));
  };

  useEffect(() => { 
    dispatch(loading(false));
  }, []);

  // useEffect to handle the redirect response
  useEffect(() => {
    instance
        .handleRedirectPromise()
        .then(async (response) => {
          if (response) {
            // This is where you'll land after the redirect and authentication.
            instance.setActiveAccount(response.account);
            const { idToken } = response;
            await loginSsoAzure(idToken);
          }
        })
        .catch((error) => {
          console.error('Handle redirect promise error:', error);
        });
  }, [instance]);

  const handleLogin = () => {
    instance
      .loginRedirect({
        scopes: ["User.Read"], // Define the scopes required for your app
        prompt: "select_account", // Forces the user to select an account each time
      })
      .then(async (response) => {
        console.log("Login success:", response);
        instance.setActiveAccount(response.account);
        const { idToken } = response;
        await loginSsoAzure(idToken);
      })
      .catch((error) => {
        console.log("Login failed:", error);
      });
  };

  const loginSsoAzure = async (idToken) => {
    dispatch(loading(true));
    try {
      const res = await loginSso(idToken);
      const session = res.data;
      localStorage.setItem("auth", JSON.stringify(session));
      dispatch(loginSuccess(session));
      dispatch(loading(false));
      history.push("/analysis/dashboard");
    } catch (error) {
      dispatch(loading(false));
    }
  };

  return (
    <>
      <div className="wrapper-container-login">
        <div className="container-login">
          <div className="col-login-left">
            <div className="module-w-padding">
              <form onSubmit={handleSubmit(onSubmit)}>                
                <h1 className="container-login_title"> {t("loginUser")} </h1>
                <input
                  type="text"
                  name="username"
                  placeholder={t("user")}
                  ref={register({
                    required: {
                      value: true,
                      message: t("validateUserRequired"),
                    },
                  })}
                />

                {errors.username && (
                  <TextDangerStyled>{errors.username.message}</TextDangerStyled>
                )}
                <input
                  type="password"
                  name="password"
                  placeholder={t("password")}
                  ref={register({
                    required: {
                      value: true,
                      message: t("validatePasswordRequired"),
                    },
                  })}
                />
                {errors.password && (
                  <TextDangerStyled>{errors.password.message}</TextDangerStyled>
                )}
                <button type="submit" className="container-login_btn-login">
                  {" "}
                  {t("loginUser")}{" "}
                </button>
                <button type="button" class="btn-Microsoft" onClick={handleLogin}> <img src={AzureIcon} />{t("loginSso")}</button>                
              </form>
            </div>
          </div>          
        </div>
      </div>
    </>
  );
};

export default LoginForm;
